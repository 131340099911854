import '../css/coupon.css';

//
// 要素の高さを揃える
//
function adjustHeight(classNamePattern) {
    var $adjustHeightElements = $(classNamePattern);

    $adjustHeightElements.css('height', 'auto');

    var maxHeight = 0;
    $adjustHeightElements.each(function () {
        var elementHeight = $(this).height();
        if (elementHeight > maxHeight) {
            maxHeight = elementHeight;
        }
    });

    $adjustHeightElements.height(maxHeight);
}

function adjustAllHeights() {
    $('[class*="js-adjust-height"]').each(function () {
        var classes = $(this).attr('class').split(' ');
        classes.forEach(function (className) {
            if (className.startsWith('js-adjust-height')) {
                var classPattern = '.' + className;
                if (className.endsWith('-pc') && window.innerWidth >= 768) {
                    adjustHeight(classPattern);
                } else if (className.endsWith('-sp') && window.innerWidth < 768) {
                    adjustHeight(classPattern);
                } else if (!className.endsWith('-pc') && !className.endsWith('-sp')) {
                    adjustHeight(classPattern);
                }
            }
        });
    });
}

$(window).resize(function () {
    adjustAllHeights();
});

adjustAllHeights();


//
// クーポン用モーダル
//
let modalScrollPosition = 0;

function disableScroll() {
    modalScrollPosition = $(window).scrollTop();
    $('body').css({
        overflow: 'hidden',
        position: 'fixed',
        top: -modalScrollPosition + 'px',
        width: '100%',
        height: 'auto',
    });
    $('html').css({ // ios対策
        overflow: 'auto',
    });
}

function enableScroll() {
    $('body').css({
        overflow: 'initial',
        position: '',
        top: '',
        width: '',
        height: '',
    });
    $('html').css({
        overflow: 'visible',
    });
    $(window).scrollTop(modalScrollPosition);
}

$('.coupon-modal-button').on('click', function () {
    var modalId = $(this).data('modal-target');
    var $modal = $('#' + modalId);

    clearModal();
    var coupon_id = $(this).data('coupon-id');

    var couponData = {};

    $('input[name^="coupon[' + coupon_id + ']"]').each(function () {
        var nameAttr = $(this).attr('name');
        var matches = nameAttr.match(/\['(.*?)'\]/); // Extract key from name attribute

        if (matches && matches[1]) {
            var name = matches[1];
            var value = $(this).val();
            couponData[name] = value;
        }
    });

    setDataForModal(couponData);

    if ($modal.length && $modal.hasClass('coupon-modal')) {
        $modal.addClass('show');
        disableScroll();
    }
});

$('.coupon-modal-close').on('click', function () {
    var $modal = $(this).closest('.coupon-modal');

    if ($modal.length) {
        $modal.removeClass('show');
        enableScroll();
    }
});

// モーダルを閉じる処理（モーダル外クリック）
$(window).on('click', function (event) {
    if ($(event.target).hasClass('coupon-modal')) {
        $(event.target).removeClass('show');
        enableScroll();
    }
});

function clearModal() {
    $('#modal_coupon_name').html('');

    $('#modal_coupon_trigger_name').html('');
    $('#modal_period_issued').html('');
    $('#modal_shop_issued').html('');
    $('#modal_category_issued').html('');

    $('#modal_shop_issued_title').removeClass('d-none');
    $('#modal_shop_issued').removeClass('d-none');
    $('#modal_category_issued_title').removeClass('d-none');
    $('#modal_category_issued').removeClass('d-none');

    $('#modal_coupon_issued_title').removeClass('d-none');
    $('#modal_coupon_issued').removeClass('d-none');
    $('#modal_coupon_issued').html('');

    $('#modal_period_available').html('');
    $('#modal_available_amount').html('');
    $('#modal_min_price').html('');
    $('#modal_max_discount_price').html('');
    $('#modal_available_count').html('');
    $('#modal_shop_available').html('');
    $('#modal_category_available').html('');
    $('#modal_coupon_detail').attr('href', '');

    $('#modal_category_issued_title').removeClass('d-none');
    $('#modal_category_issued').removeClass('d-none');
    $('#modal_category_available_title').removeClass('d-none');
    $('#modal_category_available').removeClass('d-none');

    $('#modal_max_discount_price_title').removeClass('d-none');
    $('#modal_max_discount_price').removeClass('d-none');

    $('#issued-condition-block').removeClass('d-none');
    $('#issued-condition-content').removeClass('d-none');
    $('#issued-condition-bulk-issuance').addClass('d-none');
    $('#issued-condition-bulk-issuance').html('');
    $('#issued-condition-block').removeClass('d-none');

    $('#issued-link').removeClass('d-none');
    $('#issued-detail-link').addClass('d-none');
    $('#issued-detail-link').attr('href', '');

    // Initially hide all blocks
    if (!$('#coupon_shop_block').hasClass('d-none')) {
        $('#coupon_shop_block').addClass('d-none');
    }
    if (!$('#coupon_shop_issued_title').hasClass('d-none')) {
        $('#coupon_shop_issued_title').addClass('d-none');
    }
    if (!$('#coupon_shop_issued_list').hasClass('d-none')) {
        $('#coupon_shop_issued_list').addClass('d-none');
    }
    if (!$('#coupon_shop_available_title').hasClass('d-none')) {
        $('#coupon_shop_available_title').addClass('d-none');
    }
    if (!$('#coupon_shop_available_list').hasClass('d-none')) {
        $('#coupon_shop_available_list').addClass('d-none');
    }

    $('#issued-link').attr('href', '');
    $('#available-link').attr('href', '');
}

function setDataForModal(Coupon) {
    $('#modal_coupon_name').html(Coupon.coupon_name);

    $('#modal_coupon_trigger_name').html(Coupon.coupon_trigger_name);
    $('#modal_period_issued').html(Coupon.coupon_issued_period);

    $('#modal_period_available').html(Coupon.coupon_available_period);
    $('#modal_available_amount').html(Coupon.coupon_available_amount);
    $('#modal_min_price').html(Coupon.coupon_min_price);
    $('#modal_max_discount_price').html(Coupon.coupon_max_discount_price);

    if (Coupon.coupon_max_discount_price == undefined) {
        $('#modal_max_discount_price_title').addClass('d-none');
        $('#modal_max_discount_price').addClass('d-none');
    }

    $('#modal_available_count').html(Coupon.coupon_available_count);

    if (Coupon.coupon_category_available) {
        $('#modal_category_available').html(Coupon.coupon_category_available);
    } else {
        $('#modal_category_available_title').addClass('d-none');
        $('#modal_category_available').addClass('d-none');
    }

    $('#modal_coupon_detail').attr('href', Coupon.coupon_detail_url);

    if (Coupon.is_bulk_issuance) {
        $('#issued-link').addClass('d-none');
        if (Coupon.coupon_issued_condition != '') {
            $('#issued-condition-bulk-issuance').html(Coupon.coupon_issued_condition);
            $('#issued-condition-content').addClass('d-none');
            $('#issued-condition-bulk-issuance').removeClass('d-none');
        } else {
            $('#issued-condition-block').addClass('d-none');
        }

        if (Coupon.coupon_detail_url_input != '') {
            $('#issued-detail-link').removeClass('d-none');
            $('#issued-detail-link').attr('href', Coupon.coupon_detail_url_input);
        }
    }

    if (Coupon.is_new_member_registration) {
        $('#issued-link').addClass('d-none');
        $('#modal_shop_issued_title').addClass('d-none');
        $('#modal_shop_issued').addClass('d-none');
        $('#modal_category_issued_title').addClass('d-none');
        $('#modal_category_issued').addClass('d-none');

        $('#modal_coupon_issued_title').addClass('d-none');
        $('#modal_coupon_issued').addClass('d-none');
    } else {
        if (Coupon.coupon_category_issued) {
            $('#modal_category_issued').html(Coupon.coupon_category_issued);
        } else {
            $('#modal_category_issued_title').addClass('d-none');
            $('#modal_category_issued').addClass('d-none');
        }

        // If coupon_shop_issued_list is not empty, show issued title and list
        if (Coupon.coupon_shop_issued_list) {
            $('#coupon_shop_block').removeClass('d-none');
            $('#modal_shop_issued_title').addClass('d-none');
            $('#modal_shop_issued').addClass('d-none');
            $('#coupon_shop_issued_title').removeClass('d-none');
            $('#coupon_shop_issued_list').removeClass('d-none').html(Coupon.coupon_shop_issued_list);
        } else {
            $('#modal_shop_issued_title').removeClass('d-none');
            $('#modal_shop_issued').removeClass('d-none');
            $('#modal_shop_issued').html(Coupon.coupon_shop_issued);
        }

        $('#modal_coupon_issued_title').removeClass('d-none');
        $('#modal_coupon_issued').removeClass('d-none');
        $('#modal_coupon_issued').html(Coupon.coupon_issued);
    }

    // If coupon_shop_available_list is not empty, show available title and list
    if (Coupon.coupon_shop_available_list) {
        $('#coupon_shop_block').removeClass('d-none');
        $('#modal_shop_available_title').addClass('d-none');
        $('#modal_shop_available').addClass('d-none');
        $('#coupon_shop_available_title').removeClass('d-none');
        $('#coupon_shop_available_list').removeClass('d-none').html(Coupon.coupon_shop_available_list);
    } else {
        $('#modal_shop_available_title').removeClass('d-none');
        $('#modal_shop_available').removeClass('d-none');
        $('#modal_shop_available').html(Coupon.coupon_shop_available);
    }

    let searchUrl = $('#available-link').data('url');

    searchUrl += `?coupon_id=${Coupon.id}`;

    $('#available-link').attr('href', searchUrl + '&available');
    $('#issued-link').attr('href', searchUrl);
}

$('#form-order-by .coupon_condition_order_by').change(function () {
    $("#form-order-by").submit();
});

$('.coupon-modal-open').on('click', function () {
    let id = $(this).data('id');

    let couponBlock = $('#available-review');
    couponBlock.html('');

    let couponsReview = $('.coupon-review-' + id);
    let couponsIssued = $('.coupon-issued-' + id);

    const addCoupon = (message, coupons) => {
        if (coupons.length == 0) {
            return;
        }

        let items = '';

        coupons.each(function () {
            let href = $(this).data('href');
            let kind = $(this).data('kind');
            let summary = $(this).data('summary');
            let bgClass = $(this).data('class');

            items += `
            <a class="col-item mc-coupon ${bgClass}" href="${href}">
                <table>
                    <tr>
                    <th><i class="icon icon-coupon-wh"></i></th>
                    <td><p class="coupon-name">${kind}</p><p class="coupon-summary">${summary}</p></td>
                    </tr>
                </table>
            </a>`;
        });

        couponBlock.append(`
            <p class="mt-3s mt-md-4s">${message}</p>
            <div class="grid-container mt-3s mt-md-4s">
                ${items}
            </div>
        `);
    }

    addCoupon(couponBlock.data('message-review'), couponsReview);
    addCoupon(couponBlock.data('message-issued'), couponsIssued);
});

// Coupon slider on mobile
document.addEventListener('touchmove', function (event) {
    let target = event.target;

    if (event.target.closest('.coupon-slider-item')) {
        let currentElement = target.closest('ul');
        let ul = $(currentElement);

        let li = event.target.closest('.coupon-slider-item');
        let index = $(li).index();
        let isLoading = $(ul).attr('data-loading');
        let totalLi = $(ul).find('li').length;

        if (index + 5 >= totalLi && isLoading == 'false') {
            loadMoreCouponsSlider(ul, ul);
        }
    }
});

// Coupon slider on desktop
$('.js-shop-coupon-slider-load-more').on('click', function () {
    let ul = $(this).parent().parent().find('ul');
    let currentCount = Number(ul.attr('data-current-count')) + 5;
    let totalLi = Number(ul.find('li').length);
    let thisButton = $(this);
    let isLoading = $(thisButton).attr('data-loading');

    if (currentCount + 3 >= totalLi && isLoading == 'false') {
        loadMoreCouponsSlider(thisButton, ul);
    }
});

function loadMoreCouponsSlider(thisButton, ul) {
    $(thisButton).attr('data-loading', 'true');

    let data = {
        'page': Number($(thisButton).attr('data-page'))
    };

    $.ajax({
        type: "GET",
        url: $(thisButton).data('url'),
        data: data,
        headers: {
            'ECCUBE-CSRF-TOKEN': $('meta[name="eccube-csrf-token"]').attr('content')
        },
        success: function (response) {
            if (response.template) {

                ul.append(response.template);

                adjustAllHeights();

                $(thisButton).removeClass('disabled');

                $(thisButton).attr('data-page', Number($(thisButton).attr('data-page')) + 1);

                $(thisButton).attr('data-loading', 'false');
            }
        }
    });
}